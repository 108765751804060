@import 'variables';

.sm-content-text {
  font-family: $emi-font-family-regular;
  font-size: $font-size-sm;
  color: $color-text-black;
  letter-spacing: 0.41px;
  line-height: 24px;
  margin: 0px;
}

.sm-title-text {
  font-family: $emi-font-family-bold;
  font-size: $font-size-sm;
  color: $color-text-black;
  letter-spacing: 0.47px;
  line-height: 24px;
  margin: 0px;
}

.md-title-text {
  font-family: $emi-font-family-bold;
  font-size: $font-size-md;
  color: $color-text-black;
  letter-spacing: 0.47px;
  line-height: 24px;
  margin: 0px;
}

.lg-title-text {
  font-family: $emi-font-family-bold;
  color: $color-text-black;
  letter-spacing: 0.59px;
  font-size: $font-size-lg;
  line-height: 24px;
  margin: 0px;
}
