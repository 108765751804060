@font-face {
  font-family: 'Montserrat-Bold';
  src: url('/assets/fonts/Montserrat-Bold.ttf') format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'Montserrat-SemiBold';
  src: url('/assets/fonts/Montserrat-SemiBold.ttf') format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'Montserrat-Medium';
  src: url('/assets/fonts/Montserrat-Medium.ttf') format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'Montserrat-Regular';
  src: url('/assets/fonts/Montserrat-Regular.ttf') format('truetype');
  letter-spacing: 0.38px;
  font-weight: normal;
}

// Quill Fonts

@font-face {
  font-family: 'DM-Sans';
  src: url('/assets/fonts/quill-fonts/DMSans_24pt-Regular.ttf') format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'Source-Serif-Pro';
  src: url('/assets/fonts/quill-fonts/SourceSerifPro-Regular.otf') format('opentype');
  font-weight: normal;
}
