@import './styles/index.scss';

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: $emi-font-family-regular;
  color: $color-text-black;
}

svg {
  display: block;
}
