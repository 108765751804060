@use '@angular/material' as mat;

@import './palettes';

// Colors

$color-primary: mat.get-color-from-palette($emi-primary-palette, 500);
$color-secondary: mat.get-color-from-palette($emi-secondary-palette, 500);
$color-warn: mat.get-color-from-palette($emi-warn-palette, 500);

$color-warn-light: mat.get-color-from-palette($emi-warn-palette, 300);

$color-white: #ffffff;
$color-primary-dark: #263670;
$color-primary-light: #ebf0ff;
$color-blue-light: #7e92d8;
$color-text-black: #242424;
$color-text-grey: #5a5a5a;
$color-green: #34ac66;
$color-lettuce-green: #7eb301;
$color-grey-light: #f7f7f7;
$color-field-border: #d5d5d5;
$color-placeholder: #a2a2a2;

// Font Sizes

$font-size-xxxs: 11px;
$font-size-xxs: 12px;
$font-size-xs: 13px;
$font-size-sm: 14px;

$font-size-md: 16px;

$font-size-lg: 20px;
$font-size-xl: 24px;

// Font Family

$emi-font-family-bold: 'Montserrat-Bold';
$emi-font-family-semi-bold: 'Montserrat-SemiBold';
$emi-font-family-medium: 'Montserrat-Medium';
$emi-font-family-regular: 'Montserrat-Regular';

// Global Variables

$sidenav-panel-width: 200px;
