@import 'variables';

.cursor-pointer {
  cursor: pointer;
}

.emi-hint {
  color: $color-text-grey;
  font-size: $font-size-xxs;
  letter-spacing: 0.35px;
  line-height: 20px;
}

.emi-entities-count {
  height: 14px;
  min-width: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: $emi-font-family-bold;
  background: $color-primary-light;
  font-size: $font-size-xxxs;
  color: $color-primary;
  letter-spacing: 0.32px;
  box-sizing: border-box;
  padding: 1px 5px 0 5px;
  border-radius: 4px;
}

.emi-vertical-divider {
  width: 1px;
  height: 100%;
  background: $color-field-border;
}

.emi-overlay-panel-close-button {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: $color-white;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  cursor: pointer;
}

.emi-table-placeholder {
  display: flex;
  align-items: center;
  flex-direction: column;

  &__title {
    margin-bottom: 16px;
  }
}

.table-bottom-sheet-delete-button {
  &__icon {
    margin-right: 4px;
  }
}
