@import 'variables';

.emi-form-field-input {
  border: 0;
  padding: 0;
  outline: none;
  line-height: 18px;
  width: 100%;
  font-family: $emi-font-family-regular;
  font-size: $font-size-sm;
  color: $color-text-black;
  letter-spacing: 0.41px;
}

form.readonly {
  .emi-form-field-input {
    background: $color-grey-light;
  }
}

.emi-small-input {
  height: 24px;
  font-size: $font-size-sm;
  box-sizing: border-box;
  background: $color-white;
  border: 1px solid $color-field-border;
  font-family: $emi-font-family-regular;
  letter-spacing: 0.41px;
  border-radius: 4px;
  outline: none;

  &::placeholder {
    color: $color-text-grey;
  }

  &:focus {
    border-color: $color-blue-light;
  }

  &.ng-invalid {
    &.ng-touched {
      border-color: $color-warn;
    }
  }
}

.emi-readonly-input {
  height: 32px;
  display: flex;
  align-items: center;
  border-radius: 4px;
  background: $color-grey-light;
  font-family: $emi-font-family-regular;
  font-size: $font-size-sm;
  color: $color-text-black;
  letter-spacing: 0.41px;
  line-height: 24px;
  padding: 0 8px;
}
