@use '@angular/material' as mat;

@include mat.core();

@import 'palettes';
@import 'variables';

$emi-frontend-primary: mat.define-palette($emi-primary-palette);
$emi-frontend-accent: mat.define-palette($emi-secondary-palette);
$emi-frontend-warn: mat.define-palette($emi-warn-palette);

$emi-typography: mat.define-typography-config(
  $font-family: $emi-font-family-regular,
);

$emi-frontend-theme: mat.define-light-theme(
  (
    color: (
      primary: $emi-frontend-primary,
      accent: $emi-frontend-accent,
      warn: $emi-frontend-warn,
    ),
    typography: $emi-typography,
  )
);

@include mat.all-component-themes($emi-frontend-theme);

// Menu

.mat-mdc-menu-panel {
  min-width: 140px !important;
}

.mat-elevation-z8,
.mat-mdc-elevation-specific.mat-elevation-z8 {
  background: #ffffff;
  box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.15) !important;
  border-radius: 4px;
}

.mat-mdc-menu-content {
  padding: 0 !important;
}

.emi-menu-item {
  width: 100%;
  height: 32px;
  border: none;
  outline: none;
  background: none;
  padding: 0 8px;
  display: flex;
  align-items: center;
  text-align: start;
  font-family: $emi-font-family-medium;
  font-size: $font-size-sm;
  color: $color-text-black;
  letter-spacing: 0.41px;
  cursor: pointer;

  &__icon {
    margin-right: 8px;
  }
}

// Tooltip

.mat-mdc-tooltip-panel {
  .mdc-tooltip__surface {
    max-width: 80vh !important;
  }
}

// Drag And Drop

.emi-table-row-drag-preview {
  emi-table-data,
  emi-table-row-actions {
    border-width: 0;
    border-style: solid;
    border-color: $color-blue-light;
    border-top-width: 2px;
    border-bottom-width: 2px;
    background: $color-primary-light;

    &:first-child {
      border-left-width: 2px;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }

    &:last-child {
      border-right-width: 2px;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }
}

.emi-session-drag-preview {
  border-radius: 4px;
  border: 2px solid $color-blue-light;
  border-top: 2px solid $color-blue-light !important;
  background: $color-primary-light;
}

.emi-module-drag-preview {
  border-radius: 4px;
  border: 2px solid $color-blue-light;
}

// Snackbar

.mdc-snackbar {
  &.failure {
    .mdc-snackbar__surface {
      background: $color-warn-light !important;
    }
  }
}

// Dialog

.emi-dialog-panel {
  .mat-mdc-dialog-surface {
    border-radius: 4px !important;
    box-shadow: 0 10px 40px 0 rgba(38, 41, 45, 0.5) !important;
  }
}

// Checkbox

mat-checkbox {
  .mdc-checkbox {
    padding: 0 !important;
  }

  .mat-ripple,
  .mdc-checkbox__ripple {
    display: none !important;
  }

  .mdc-checkbox__background {
    width: 16px;
    height: 16px;
    box-sizing: border-box;
    top: 0 !important;
    left: 0 !important;
    background: transparent !important;
    border: 1px solid $color-field-border !important;
    border-radius: 4px;

    .mdc-checkbox__checkmark {
      transition: 0s !important;
    }
  }

  .mdc-checkbox__checkmark-path {
    stroke: $color-primary !important;
    d: path('M 5 11.7 L 11 16.6 L 19 7') !important;
  }

  label {
    padding-left: 8px !important;
    padding-bottom: 2px !important;
    font-size: $font-size-sm;
    letter-spacing: 0.41px;
    line-height: 24px;
    user-select: none;
  }

  &.mat-mdc-checkbox-checked {
    .mdc-checkbox__background {
      color: $color-primary !important;
      border-color: $color-primary !important;
      border-width: 2px !important;
    }
  }

  .mdc-checkbox__native-control {
    top: 0 !important;
    left: 0 !important;
    height: auto !important;
    width: auto !important;
  }
}

.mat-pseudo-checkbox {
  width: 16px !important;
  height: 16px !important;
  border: 1px solid $color-field-border !important;
  background: $color-white !important;
  border-radius: 4px !important;

  &.mat-pseudo-checkbox-checked {
    border-width: 2px !important;
    border-color: $color-primary !important;

    &::after {
      width: 5px !important;
      height: 3px !important;
      border-color: $color-primary;
      top: -2.5px !important;
    }
  }
}

// Autocomplete / Select

.mat-mdc-autocomplete-panel,
.mat-mdc-select-panel {
  padding: 0 !important;
  border: 1px solid $color-blue-light;
  border-radius: 4px !important;
}

// Select

.mat-mdc-select-panel {
  margin-left: -1px !important;
  box-shadow: none !important;
}

mat-select {
  border: 1px solid $color-field-border;
  box-sizing: border-box;
  border-radius: 4px;

  &:hover {
    border-color: $color-text-grey;
  }

  &.focused {
    border-color: $color-blue-light;
  }

  &.ng-invalid.ng-touched {
    border-color: $color-warn;
  }

  .mat-mdc-select-trigger {
    height: 30px !important;
    padding: 0 8px !important;

    .mat-mdc-select-min-line {
      font-size: $font-size-sm;
      color: $color-text-black;
      -webkit-font-smoothing: initial !important;
      letter-spacing: 0.41px;

      &.mat-mdc-select-placeholder {
        color: $color-text-grey;
      }
    }
  }
}

// Option

.mat-mdc-option {
  height: 32px;
  min-height: 32px !important;
  padding: 0 8px !important;

  &.mdc-list-item--selected {
    background-color: $color-primary-light !important;
  }

  .mdc-list-item__primary-text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap !important;
    font-family: $emi-font-family-regular !important;
    -webkit-font-smoothing: initial !important;
    font-size: $font-size-sm !important;
    letter-spacing: 0.41px;
    line-height: 24px;
  }
}

// Radio Button

.mat-mdc-radio-group {
  display: grid;
}

.mat-mdc-radio-button {
  label {
    font-family: $emi-font-family-regular;
    font-size: $font-size-sm;
    color: $color-text-black;
    letter-spacing: 0.41px;
    font-weight: normal;
    -webkit-font-smoothing: initial !important;
  }

  .mat-mdc-radio-touch-target {
    width: 22px;
    height: 22px;
  }

  .mdc-radio {
    width: 16px !important;
    height: 16px !important;
    padding: 6px 6px 6px 0 !important;

    &__background {
      width: 16px !important;
      height: 16px !important;

      &::before {
        display: none !important;
      }
    }

    &__inner-circle {
      top: -2px !important;
      left: -2px !important;
    }
  }

  &:not(.mat-mdc-radio-checked) {
    .mdc-radio__outer-circle {
      border: 1px solid $color-field-border !important;
    }
  }

  &.mat-mdc-radio-checked {
    .mdc-radio__inner-circle {
      transform: scale(0.4) !important;
    }
  }
}
